import React from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import { Box } from "@mui/material"
import PortableText from "../components/PortableText"
import "react-multi-carousel/lib/styles.css"
import moment from "moment"

const ArchivePost1 = ({ author, _rawBody, images, _createdAt, title }) => {
  let inner = images.map((image, i) => (
    <div key={i} className="col-md-6">
      <div
        style={{
          overflow: "hidden",
        }}
      >
        <SanityImage
          {...image}
          //   width={300}
          style={{
            display: "block",
            width: "100%",
            height: "calc(90vh - 200px)",
            // height: '100%',
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  ))
  return (
    <>
      <Box
        className="archive_page d-flex flex-xl-row flex-column-reverse"
        marginLeft={{ md: 6 }}
      >
        <div className="archive_left-column col-xl-6">
          <Box
            paddingRight={{ md: 10 }}
            className="row archive_photo-block"
            marginBottom={10}
          >
            {inner}
          </Box>
        </div>
        <div className="archive_right-column col-xl-6">
          <Box paddingRight={{ md: 10 }} marginBottom={10}>
            <div className="archive_title">{title}</div>
            <div className="archive_created">
              {moment(_createdAt).format("dddd, MMMM Do YYYY")}
            </div>
            <div className="archive_author">Written by {author}</div>
            <PortableText blocks={_rawBody} className="archive_body" />
          </Box>
        </div>
      </Box>
    </>
  )
}

export default ArchivePost1
