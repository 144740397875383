import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { Seo } from "../../components/seo"
import { Layout } from "../../components/layout"
import ArchivePost1 from "../../components/archive-1"
import ArchivePost2 from "../../components/archive-2"

export default function ArchivePost({
  data: {
    sanityArchivePost: { template = "Template 1", ...rest },
  },
}) {
  const body =
    template === "Template 1" ? (
      <ArchivePost1 {...rest} />
    ) : (
      <ArchivePost2 {...rest} />
    )
  return <Layout>{body}</Layout>
}

export const query = graphql`
  query ($id: String!) {
    sanityArchivePost(id: { eq: $id }) {
      author
      _createdAt
      _rawBody
      title
      template
      images {
        ...ImageWithPreview
      }
    }
  }
`
