import React from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import { Box } from "@mui/material"
import PortableText from "../components/PortableText"
import "react-multi-carousel/lib/styles.css"
import moment from "moment"
import { Container } from "react-bootstrap"

const ArchivePost2 = ({ author, _rawBody, images, _createdAt, title }) => {
  let inner = images.map((image, i) => (
    <Box
      key={i}
      marginBottom={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {/* <div
        style={{
          overflow: 'hidden',
        }}
      > */}
      <SanityImage
        {...image}
        width={2000}
        style={{
          display: "block",
          maxWidth: "800px",
          width: "auto",
          height: "auto",
          maxHeight: "800px",
          // height: 'calc(90vh - 200px)',
          // height: '100%',
          // objectFit: 'cover',
        }}
      />
      {/* </div> */}
    </Box>
  ))
  return (
    <>
      <Box
        className="archive_page d-flex flex-column align-items-center text-center"
        marginLeft={{ md: 6 }}
        marginBottom={10}
      >
        <Container>
          <Box
            paddingRight={{ md: 10 }}
            marginBottom={5}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <div className="archive_title">{title}</div>
            <div className="archive_created">
              {moment(_createdAt).format("dddd, MMMM Do YYYY")}
            </div>
            <div className="archive_author">Written by {author}</div>
            <PortableText blocks={_rawBody} className="archive_body-archive2" />
          </Box>
        </Container>
        <div className="archive_images-archive2">{inner}</div>
      </Box>
    </>
  )
}

export default ArchivePost2
